@import "../../sass/variables";
.button {
    font-weight: 700;
    display: inline-block;
    cursor: pointer;
    transition: all ease 0.2s;
    text-align: center;
    border: none;
    font-size: _rem(16px);
    height: 48px;
    line-height: 48px;
    border-radius: 8px;
    text-decoration: none !important;
    padding: 0 25px;
    &.primary {
        background-color: map-get($colors, "primary");
        box-shadow: 4px 4px 12px rgba(110, 34, 181, 0.5);
        color: #fff !important ;
        &:hover,
        &:focus,
        &:active {
            background-color: darken(map-get($colors, "primary"), 5);
        }
    }

    &.secondary {
        background-color: map-get($colors, "secondary");
        color: #000 !important ;
        border-radius: 24px;
        position: relative;
        &:after {
            content: "";
            left: 30px;
            right: 30px;
            height: 2px;
            background-color: map-get($colors, "primary");
            position: absolute;
            bottom: 0;
        }
        &:hover,
        &:focus,
        &:active {
            background-color: darken(map-get($colors, "secondary"), 5);
        }
        &:disabled {
            &:after {
                display: none;
            }
        }
    }
    &.border{
        border: solid 1px map-get($colors, "primary");
        background-color: transparent;
        color: map-get($colors, "primary");
        outline: none  !important;
    }
    &.full {
        width: 100%;
    }
    &.small {
        font-size: _rem(14px);
        height: 32px;
        line-height: 32px;
        border-radius: 6px;
    }
    &.secondary.small {
        border-radius: 20px;
    }

    &:disabled {
        background: #d0d0d0 !important;
        cursor: default !important;
        color: #fff !important;
        box-shadow: none !important;
    }
    .loader{
        margin-left: 10px ;
    }
}