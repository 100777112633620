@import "../../sass/variables";
.wrapper {
    display: flex;
    width: 100%;
    min-height: 100vh;
    .sidebar {
        display: flex;
        flex: auto;
        max-width: map-get($settings, "menu-width");
        min-width: map-get($settings, "menu-width");
        @include small_screen {
            max-width: 80px;
            min-width: 80px;
        }
    }
    .contents {
        display: flex;
        flex: auto;
        padding: 20px;
        .inner {
            background-color: rgb(255, 255, 255);
            width: 100%;
            border-radius: 20px;
        }
    }
    .sidebar_right {
        display: flex;
        flex: auto;
        max-width: map-get($settings, "sidebar-width");
        min-width: map-get($settings, "sidebar-width");
        padding: 20px;
        padding-left: 0;
        @include small_screen {
            position: fixed;
            left: 100%;
            top: 0;
            bottom: 0;
            transition: all ease .2s ;
            box-shadow: -8px 0px 19px -14px rgba(0 ,0, 0 , 0.2);
            background: #fff;
            z-index: 50;
        }
        &.sidebar_open{
            transform: translateX(-300px);  
        }
    }
    .overlay{
        display: block ;
        position: fixed ;
        right: 0 ;
        left: 0 ;
        bottom: 0 ;
        top: 0 ;
        background-color: rgba(0,0,0,.5);
        transform: translateX(100%);
        display:none ;
        opacity: 0 ;
        transition: visibility 0s, opacity 0.5s linear,transform 1s linear;
        transform-origin: right center ;
        z-index: 50;
        &.visible{
            transform: translateX(0%);
            display:block ;
            opacity: 1;
        }
    }
    $size : 60px ;
    .mobile_actions{
        position: fixed ;
        right: $size ;
        bottom: $size  ;
        display: none  ;
        justify-content: center;
        align-items: flex-end;
        height: $size+15;
        display:none ;
        @include small_screen{
            display:flex ;
        }
        .inner{
            display:flex ;
            position: relative;
            .button{
                width: $size ;
                height: $size  ;
                background-color: map-get($colors,"primary");
                text-align: center ;
                line-height: $size ;
                color: #fff ;
                border-radius: 50% ;
                cursor:pointer ;
                position: relative;
                .count{
                    position: absolute ;
                    right: -10px ;
                    top: 10px ;
                    background-color: lighten(map-get($colors,"primary"),10);
                    color: #fff ;
                    width: 30px ;
                    height: 30px ;
                    line-height: 30px ;
                    font-size: _rem(12px);
                    border-radius: 50% ;

                }
                &.add_new{
                    position: absolute ;
                    right: 0 ;
                    left: 0 ;
                    top: 0 ;
                    bottom: 0 ;
                    transition: all ease .2s ;
                    opacity: 0;
                }
            }
         
        }
        &:hover,&:focus{
            .inner{
                .button{
                    &.add_new{
                        transform: translateY( -#{$size+15});
                        opacity: 1;
                    }
                }
            }
        }
    }
}
.admin_dashboard{
    display:block ;
}