$colors: (
    "primary": #6e22b5,
    "secondary": #f1f5ff,
    "gray": #f3f3f3,
);
$card: (
    "total_complaints": #6671e0,
    "completed": #67c4fb,
    "completed_replacement": #f9ae5d,
    "ongoing_complaints": #32da7e,
);

$table: (
    "head-bg": map-get($colors, "secondary"),
    "tr-border": map-get($colors, "gray"),
);

$settings: (
    "menu-width" : 340px  ,
    "sidebar-width" : 300px
);

@function _rem($size) {
    $remSize: $size / 16px;
    @return #{$remSize}rem;
}


@mixin small_screen {
    @media (max-width: 1400px) { @content; }
}