.wrapper {
    display: block;
    width: 100%;
    min-height: 100vh;
    text-align: center;
    justify-content: center;
    align-items: center;
    .box {
        width: 100%;
        max-width: 450px;
        display: inline-block;
        margin-top: 15%;
    }
    .error_wrapper {
        max-width: 288px;
        display: inline-block;
        width: 100% ;
    }
    .input_wrapper {
        display: inline-block;
        max-width: 288px;
        width: 100%;
        position: relative;
        input {
            padding-right: 35px;
        }
        .input_icon {
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            width: 44px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }
    }
}
