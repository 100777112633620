.error{
    color: #EB7D7D ;
    font-size: 14px ;
    &.primary{
        margin-top: 10px ;
    }
    &.secondary {
        margin-top: 2px ;
    }
}
.no_field_error{
    color: #EB7D7D ;
    margin-bottom: 15px ;
    font-size: _rem(14px);
}