@import "./sass/variables";
html,
body {
    font-family: "Oxygen", sans-serif !important;
    font-size: _rem(16px) !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: map-get($colors, "secondary");
}
.app {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
}
.ec-form {
    input {
        width: 100%;
        background: #ffffff;
        box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.12);
        border-radius: 8px;
        border: none;
        height: 48px;
        padding: 0 15px;
        &.has-error {
            box-shadow: 4px 4px 12px rgba(255, 5, 5, 0.42);
        }
    }
}
@keyframes spinner {
    to {
        transform: rotate(360deg);
    }
}

.page-title {
    font-size: _rem(32px);
    font-weight: 700;
    color: #000;
}
.count {
    width: 30px;
    height: 30px;
    display: inline-block;
    line-height: 30px;
    color: #fff;
    border-radius: 50%;
    font-size: _rem(14px);
    position: relative;
    right: 8px;
    top: 0;
    bottom: 0;
    text-align: center;
    margin: auto;
    right: -14px;
    &--primary {
        background-color: map-get($colors, "primary");
    }
}
.ReactModalPortal {
    position: relative;
    z-index: 50;
}
.ReactModal__Content.ReactModal__Content--after-open {
    background-color: transparent !important;
    border: none !important;
}
form {
    position: relative;
}

ul.pagination {
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    text-align: center;
    margin-top: 20px ;
    li {
        display: inline-block;
        margin: 0 2px;
        cursor: pointer;
        a {
            padding: 3px 8px;
            display: inline-block;
            outline: none;
            color: #7d7d7d;
            text-decoration: none;
            font-weight: 700;
            text-align: center;
            min-width: 20px;
            border-radius: 3px;
            user-select: none ;
            &:hover,
            &:focus,
            &:active {
                opacity: 0.8;
            }
        }
        &.active {
            a {
                color: map-get($colors, "primary");
            }
        }
        &.next,
        &.previous {
            a {
                background-color: map-get($colors, "primary");
                color: #fff;
                &:hover,
                &:focus,
                &:active {
                    background-color: darken(map-get($colors, "primary"), 10);
                    opacity: 1;
                }
            }
        }
        &.disabled {
            a {
                background-color: #d0d0d0 !important;
            }
        }
    }
}
body.ReactModal__Body--open{
    overflow: hidden !important;
}

.l{
    color: #000 ;
}

.close-er{
    border: none ;
    background-color:map-get($colors, "primary");
    color: #fff ;
    border-radius: 3px ;
    padding: 3px 20px ;
    cursor: pointer;
}
.link-primary{
    color: map-get($colors, "primary") !important;
    font-weight: 700 ;
}